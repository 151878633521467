<template>
  <main>
    <h1>Sean Beck</h1>
    <p>Coming soon</p>
    <a href="https://revivalcoop.com">Check Out Revival</a>
  </main>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style type="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

main p {
  animation: colorchange 20s infinite alternate;
}

 @keyframes colorchange {
    0% {
      color: red;
    }

    10% {
      color: blue;
    }

    20% {
      color: green;
    }

    30% {
      color: orange;
    }

    40% {
      color: yellow;
    }

    50% {
      color: purple;
    }

    60% {
      color: turquoise;
    }

    70% {
      color: greenyellow;
    }

    80% {
      color: salmon;
    }

    90% {
      color: royalblue;
    }

    100% {
      color: orangered;
    }
 }
</style>
